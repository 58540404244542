const isIframed = (window.location !== window.parent.location);
const isWebApp = process.env.APP_ENV === 'web';

function loadScript(src) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.id = 'callus-script';
    document.body.append(script);
}

/**
 * checkjemedicijn customers (like Plus.nl) can embed app.checkjemedicijn.nl in an iframe.
 * The chat widget can only use the 3CX VOIP script if it's allowed to use the microphone.
 * Since we don't control the iframe permissions and can't check for microphone cross-browser,
 * we use the call button as a call to `tel:{phoneNumber}` link when the web app is iframed.
 */
export default function chatWidget(el) {
    const phonesystemUrl = 'https://checkmijnmedicijn.fluxcloud.eu:5001';
    const chatWidgetSrc = `${phonesystemUrl}/callus.js`;
    const phoneLink = document.getElementById('chat-widget-link');

    if (isWebApp && isIframed) {
        phoneLink.removeAttribute('hidden');
    } else {
        if(!document.getElementById('callus-script')) {
            loadScript(chatWidgetSrc);
        }

        /*
            It happened that we had 30 chat-widgets on the page.
            Because they all started chatting with the server we got rate limited (status 429)

            To fix this we now only startup the widget when the related modals are opened
            We also cleanup the widget when modals are closed
        */
        const observer = new IntersectionObserver((records) => {
            if(records[0].isIntersecting) {
                el.innerHTML = `
                    <call-us-phone
                        class="chat-widget"
                        phonesystem-url="${phonesystemUrl}"
                        party="webchat"
                        call-title="Bel ons"
                        enable-onmobile="true"
                        animation-style="none"
                    >
                    </call-us-phone>
                `
            } else {
                el.innerHTML = ''
            }
        })

        observer.observe(el)
    }

    const callbacks = new Set();

    const oldGetUserMedia = navigator.mediaDevices.getUserMedia;
    navigator.mediaDevices.getUserMedia = function (...args) {
        return oldGetUserMedia.call(navigator.mediaDevices, ...args).then(stream => {
            // microphone is active
            callbacks.forEach(cb => cb(true));

            stream.addEventListener('inactive', () => {
                // microphone is no longer used
                callbacks.forEach(cb => cb(false));
            });
            return stream;
        });
    }

    return {
        addCallback(callback) {
            callbacks.add(callback);
        }
    }
}
