export default function inactivityTimer(callback, delay) {
    let timer;
    let counting = false;

    function stop() {
        clearTimeout(timer);
        counting = false;
    }
   
    function start() {
        timer = setTimeout(callback, delay);
        counting = true;
    }

    function reset() {
        if (counting) {
            stop();
            start();
        }
    }

    [
        'click',
        'keypress',
        'mousedown',
        'mousemove',
        'scroll',
        'touchstart',
    ].forEach((event) => document.addEventListener(event, reset, true));

    return {
        stop,
        start,
        reset
    }
}