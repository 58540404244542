export function questionCreator(questionLogic, form, modals) {
    return (el) => {
        const chatAudio = new Audio('/audios/pop_07.mp3')
        const logic = questionLogic.find(logic => logic.id === el.dataset.id);

        function handleAnswer(e) {
            const answer = e.target.value;
            const errorModalId = e.target.id;
            const showError = e.target.dataset.showError === 'true';
            const endsFlow = e.target.dataset.endsFlow === 'true';
            const enablesPregnancy = e.target.dataset.enablesPregnancy === 'true';
            const enablesDrugs = e.target.dataset.enablesDrugs === 'true';

            if (el.dataset.id === questionLogic[questionLogic.length - 1].id) {
                chatAudio.play();
            } else if (!showError) {
                setTimeout(() => chatAudio.play(), 800);
            }

            el.classList.add('question--answered');

            el.dispatchEvent(new CustomEvent('answered', {
                detail: {
                    answer,
                    endsFlow,
                    showError,
                    errorModalId,
                    enablesPregnancy,
                    enablesDrugs,
                }
            }));
        }

        el.addEventListener('change', handleAnswer);

        return {
            show() {
                el.hidden = false;
            },
            showErrorModal(endsFlowModalId, playAudio) {
                const errorModal = modals[`${endsFlowModalId}-error-modal`];
                if(errorModal) {
                    errorModal.show(playAudio);
                }
            },
            getAnswer() {
                return form.elements[logic.id].value;
            },
            shouldEndFlow(answer) {
                const answerElement = Array.from(form.elements[logic.id]).find(
                    item => item.value === answer
                );
                return answerElement.dataset.endsFlow === 'true';
            },
            getNextQuestionIndex(currentQuestionIndex) {
                return logic.next({
                    answer: this.getAnswer(),
                    currentIndex: currentQuestionIndex
                });
            }
        };
    }
}
