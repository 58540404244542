export default function instructionItemCreator(el, form) {
    const getInstructionSpecificity = instruction => {
        let s = 0;
        if (instruction.dataset.gender && instruction.dataset.gender !== 'n/a') s++;
        if (instruction.dataset.age) s++;
        if (instruction.dataset.weight) s++;
        return s;
    };

    function getLabelData(name) {
        const selectedValue = form.elements[name]
            ? form.elements[name].value
            : null;
        if (!selectedValue) return null;
        const radioButtonForSelectedValue = Array.from(
            form.elements[name]
        ).find(radio => radio.value === selectedValue);
        return radioButtonForSelectedValue.parentElement.textContent.trim();
    }

    function getGenderData() {
        const selectedValue = form.elements['gender']
            ? form.elements['gender'].value
            : null;
        return selectedValue ? selectedValue.trim() : null;
    }

    function getApplicableTexts() {
        const age = getLabelData('age');
        const weight = getLabelData('weight');
        const gender = getGenderData();
        const applicableTexts = [];

        el.forEach(item => {
            const texts = Array.from(
                item.querySelectorAll('.instruction-item__text')
            )
                .filter(item => {
                    return (
                        (!item.dataset.age || item.dataset.age === age) &&
                        (!item.dataset.gender ||
                            item.dataset.gender === gender ||
                            item.dataset.gender === 'n/a') &&
                        (!item.dataset.weight || item.dataset.weight === weight)
                    );
                })
                .sort(
                    (a, b) =>
                        getInstructionSpecificity(b) -
                        getInstructionSpecificity(a)
                );
            texts.length && applicableTexts.push(texts[0]);
        });

        return applicableTexts.flat();
    }

    return () => {
        return {
            showApplicableTexts() {
                if (getApplicableTexts().length) {
                    getApplicableTexts().forEach(text => {
                        text.hidden = false;
                        text.parentElement.parentElement.hidden = false;
                    });
                }
            }
        };
    };
}
