import inactivityTimer from "@/utils/inactivity-timer";

export default function errorOverlayCreator(el) {
    const timer = inactivityTimer(hide, 5 * 1000);

    function hide() {
        timer.stop();
        el.hidden = true;
    }

    function show() {
        if (!el.hidden) {
            return;
        }
        el.hidden = false;
        timer.start();
    }

    return {
        show,
        hide
    };
}
