module.exports = [
    {
        id: 'age',
        use: data => data.medicine.useQuestionAge,
        text: data => data.interfaceString.questionAge,
        answers: data => answersOnly(data.medicine.questionAge),
        errorMessage: data => errorMessageOnly(data.medicine.questionAge),
        next: ({ currentIndex }) => currentIndex + 1
    },
    {
        id: 'gender',
        use: data => data.medicine.useQuestionGender,
        text: data => data.interfaceString.questionGender,
        answers: data => {
            return [
                {
                    value: 'male',
                    text: data.interfaceString.genderMale
                },
                {
                    value: 'female',
                    text: data.interfaceString.genderFemale
                },
                {
                    value: 'other',
                    text: data.interfaceString.genderNeutral
                }
            ];
        },
        errorMessage: data => undefined,
        next: ({ currentIndex }) => currentIndex + 1
    },
    {
        id: 'weight',
        use: data => data.medicine.useQuestionWeight,
        text: data => data.interfaceString.questionWeight,
        answers: data => answersOnly(data.medicine.questionWeight),
        errorMessage: data => errorMessageOnly(data.medicine.questionWeight),
        next: ({ currentIndex }) => currentIndex + 1
    },
    {
        id: 'allergic',
        use: data => data.medicine.useQuestionAllergic,
        text: data => data.interfaceString.questionAllergic,
        answers: data => {
            return [...answersOnly(data.medicine.questionAllergic), nope(data)];
        },
        errorMessage: data => errorMessageOnly(data.medicine.questionAllergic),
        next: ({ currentIndex }) => currentIndex + 1
    },
    {
        id: 'use-other-medicines',
        use: data => data.medicine.useQuestionWhatOtherMedicines,
        text: data => data.interfaceString.questionUseOtherMedicines,
        answers: data => yesNoAnswers(data),
        errorMessage: () => undefined,
        next: ({ answer, currentIndex }) =>
            answer === '0' ? currentIndex + 2 : currentIndex + 1
    },
    {
        id: 'what-other-medicines',
        use: data => data.medicine.useQuestionWhatOtherMedicines,
        text: data => data.interfaceString.questionWhatOtherMedicines,
        answers: data => {
            return [
                ...answersOnly(data.medicine.questionWhatOtherMedicines),
                nope(data)
            ];
        },
        errorMessage: data =>
            errorMessageOnly(data.medicine.questionWhatOtherMedicines),
        next: ({ currentIndex }) => currentIndex + 1
    },
    {
        id: 'has-organ-problems',
        use: data => data.medicine.useQuestionWhatOrganProblems,
        text: data => data.interfaceString.questionHasOrganProblems,
        answers: data => yesNoAnswers(data),
        errorMessage: () => undefined,
        next: ({ answer, currentIndex }) =>
            answer === '0' ? currentIndex + 2 : currentIndex + 1
    },
    {
        id: 'what-organ-problems',
        use: data => data.medicine.useQuestionWhatOrganProblems,
        text: data => data.interfaceString.questionWhatOrganProblems,
        answers: data => {
            return [
                ...answersOnly(data.medicine.questionWhatOrganProblems),
                nope(data)
            ];
        },
        errorMessage: data =>
            errorMessageOnly(data.medicine.questionWhatOrganProblems),
        next: ({ currentIndex }) => currentIndex + 1
    },
    {
        id: 'discorders',
        use: data => data.medicine.useQuestionDisorders,
        text: data => data.interfaceString.questionDisorders,
        answers: data => {
            return [
                ...answersOnly(data.medicine.questionDisorders),
                nope(data)
            ];
        },
        errorMessage: data => errorMessageOnly(data.medicine.questionDisorders),
        next: ({ currentIndex }) => currentIndex + 1
    }
];

function answersOnly(items) {
    return items
        .filter(item => item.__typename !== 'QuestionDontUseErrorRecord')
        .map(item => {
            if (item.answer) {
                return item.__typename === 'QuestionAgeAnswerRecord'
                    ? {
                          enablesPregnancy: item.answer.enablesPregnancy,
                          enablesDrugs: item.answer.enablesDrugs,
                          errorTitle: item.errorTitle,
                          errorMessage: item.errorMessage,
                          showError: item.showError,
                          endsFlow: item.endsFlow,
                          text: item.answer.text,
                          value: item.answer.id,
                      }
                    : {
                          errorTitle: item.errorTitle,
                          errorMessage: item.errorMessage,
                          showError: item.showError,
                          endsFlow: item.endsFlow,
                          text: item.answer.text,
                          value: item.answer.id,
                      };
            } else {
                return {
                    errorTitle: item.errorTitle,
                    errorMessage: item.errorMessage,
                    showError: item.showError,
                    endsFlow: item.endsFlow,
                    text: item.text,
                    value: item.text,
                };
            }
        });
}

function errorMessageOnly(items) {
    const message = items.find(
        item => item.__typename === 'QuestionDontUseErrorRecord'
    );
    return message ? message.text : '';
}

function nope(data) {
    return {
        text: data.interfaceString.no,
        value: '0'
    };
}

function yup(data) {
    return {
        text: data.interfaceString.yes,
        value: '1'
    };
}

function yesNoAnswers(data) {
    return [yup(data), nope(data)];
}
