export default function warningCreator(el) {
    return {
        getModalId() {
            return el.querySelector('button').dataset.openModal;
        },

        getType() {
            return el.dataset.type;
        },

        show() {
            el.hidden = false;
        }
    }
}
