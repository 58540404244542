import './medicine.css';

import { createControllerMap, createControllerList } from '@/utils/create-controllers';
import { questionCreator } from '@/components/question/question';
import errorOverlayCreator from '@/components/error-overlay/error-overlay';
import installModalClickEvents from '@/utils/install-modal-click-events';
import instructionsCreator from '@/components/instructions/instructions';
import instructionItemCreator from '@/components/instruction-item/instruction-item';
import * as questionLogic from './question-logic';
import modalCreator from '@/components/modal/modal';
import warningCreator from '@/components/warning/warning';
import preventBfCache from '@/utils/prevent-bf-cache';
import browseHappy from '@/components/browse-happy/browse-happy';
import chatWidgetCreator from '@/components/chat-widget/chat-widget';
import initErrorLogging from '@/utils/init-error-logging';

let questionIndex = 0;
let drugsEnabled = false;
let pregnancyEnabled = false;
const form = document.getElementById('form');
const errorAudio = new Audio('/audios/error_03.mp3');
const modalAudio = new Audio('/audios/tadah_06.mp3');
const chatAudio = new Audio('/audios/pop_07.mp3');
const instructionElements = document.querySelectorAll('.instruction-item');
const controllers = initializeControllers();

initErrorLogging();
browseHappy();
preventBfCache();
setQuestionIndex(0);
installModalClickEvents(controllers.modals); // listen to click events on buttons with data-open-modal

if (process.env.APP_ENV === 'kiosk') {
    require('./medicine-kiosk').init(controllers);
}

function initializeControllers() {
    const modals = createControllerMap(document, '.modal', modalCreator);

    const chatWidgets = createControllerList(document, '#call-us-phone', chatWidgetCreator);

    const questions = createControllerList(document, '.question', questionCreator(questionLogic.default, form, modals));
    questions.forEach(question => {
        question.addEventListener('answered', handleAnswer);
    });

    const warnings = createControllerList(document, '.warning', warningCreator);
    const [instructions] = createControllerList(document, '.instructions', instructionsCreator(form));
    const [instructionItems] = createControllerList(document, '.instruction-item', instructionItemCreator(instructionElements, form));
    const [errorOverlay] = createControllerList(document, '[data-error-overlay]', errorOverlayCreator);

    return {
        chatWidgets,
        errorOverlay,
        instructionItems,
        instructions,
        modals,
        questions,
        warnings,
    };
}

function setQuestionIndex(newQuestionIndex) {
    questionIndex = newQuestionIndex;
    controllers.questions[newQuestionIndex].show();
}

function handleAnswer(e) {
    const currentQuestion = controllers.questions[questionIndex];

    if (e.detail.showError) {
        const errorModalId = e.detail.errorModalId;
        currentQuestion.showErrorModal(errorModalId, false);
        if (e.detail.endsFlow) {
            errorAudio.play();
        } else {
            modalAudio.play();
        }
    }

    if (e.detail.enablesPregnancy) {
        pregnancyEnabled = true;
    }

    if (e.detail.enablesDrugs) {
        drugsEnabled = true;
    }

    const newQuestionIndex = currentQuestion.getNextQuestionIndex(questionIndex);
    if (newQuestionIndex >= controllers.questions.length) {
        showResults();
    } else {
        setQuestionIndex(newQuestionIndex);
        // window.scroll({top: document.body.scrollHeight, behavior: 'smooth'});
        document.querySelector('.form-anchor').scrollIntoView();
    }
}

function showResults() {
    document.documentElement.classList.add('done');
    document.querySelector('.finished-container').scrollIntoView(true);

    const shouldShowPregnancyWarning = form.elements.gender
        ? form.elements.gender.value !== 'male' && pregnancyEnabled
        : pregnancyEnabled;

    const relevantWarnings = Array.from(form.elements).reduce((warnings, element) => {
        if (element.checked && element.dataset.showError) {
            warnings.push(element.id + '-error-modal');
        }
        return warnings;
    }, []);

    controllers.warnings.forEach((warning) => {
        if (
            (warning.getType() === 'answer' && !relevantWarnings.includes(warning.getModalId())) ||
            (warning.getType() === 'pregnancy' && !shouldShowPregnancyWarning) ||
            (warning.getType() === 'smoking and drugs' && !drugsEnabled) ||
            (warning.getType() === 'alcohol' && !drugsEnabled)
        ) {
            return;
        }
        warning.show();
    });

    controllers.instructions.prefillModalHeaderAnswers();
    controllers.instructionItems.showApplicableTexts();
}
