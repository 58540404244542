export default function instructionsCreator(form) {
    function getLabelTextForFormValue(name) {
        const selectedValue = form.elements[name]
            ? form.elements[name].value
            : null;
        if (!selectedValue) return null;
        const radioButtonForSelectedValue = Array.from(
            form.elements[name]
        ).find(radio => radio.value === selectedValue);
        return radioButtonForSelectedValue.parentElement.textContent;
    }

    return function (el) {
        return {
            prefillModalHeaderAnswers() {
                const modal = document.getElementById('instructions-modal');
                const age = getLabelTextForFormValue('age');
                const ageElement = modal.querySelector('[data-id="age"]'); 
                const gender = getLabelTextForFormValue('gender');
                const genderElement = modal.querySelector('[data-id="gender"]');
                const weight = getLabelTextForFormValue('weight');
                const weightElement = modal.querySelector('[data-id="weight"]');

                age ? ageElement.textContent = age : ageElement.parentElement.style.display = 'none';
                gender ? genderElement.textContent = gender : genderElement.parentElement.style.display = 'none';
                weight ? weightElement.textContent = weight : weightElement.parentElement.style.display = 'none';
            }
        };
    };
}
